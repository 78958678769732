<template>
  <DashboardsStyled>
    <ModelManager
      v-if="true"
      :title="'Dashboards'"
      :loading="loading"
      :count="count"
      :totalCount="totalCount"
      :models="models"
      :modelName="'dashboard'"
      :errors="errors"
      :hasNextPage="hasNextPage"
      :hasPreviousPage="hasPreviousPage"
      :searchQuery.sync="searchQuery"
      :editRouteName="'dashboard'"
      :hasDeletePermission="hasWritePermission"
      @prev-page="previousPage"
      @next-page="nextPage"
      @reload-models="reload"
    >
      <template v-slot:headers>
        <HeadersStyled>
          <div class="cell title left">{{ $tc('title', 2) }}</div>
          <div class="cell owner left">{{ $tc('username', 1) }}</div>
          <div class="cell">{{ $tc('widget', 2) }}</div>
          <div class="cell">{{ $tc('asset', 2) }}</div>
        </HeadersStyled>
      </template>
      <Dashboardstyled v-for="model in models" :key="model.id" :slot="model.id">
        <div class="cell title left">{{ model.title }}</div>
        <div class="cell alias left">{{ model.owner.username }}</div>
        <div class="cell">{{ model.gridItems.length }}</div>
        <div class="cell">{{ model.assets.length }}</div>
      </Dashboardstyled>
    </ModelManager>
    <router-view />
  </DashboardsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import ModelManager from '@/components/misc/ModelManager'
import { getRolesFromToken } from '@common/utils'
import { flexCenter } from '@styles/mixins'
import DASHBOARDS_CONNECTION_QUERY from '#/graphql/operations/dashboards/connectionQuery/dashboardsConnection.gql'

const DashboardsStyled = styled('div')``
const Dashboardstyled = styled('div')`
  display: grid;
  grid-template-columns: auto 12rem 12rem 12rem 6rem 6rem;
  grid-template-rows: 2.5rem;
  .cell {
    ${flexCenter}
    padding: .5rem;
    &.left {
      justify-content: flex-start;
    }
  }
`
const HeadersStyled = styled('div')`
  display: grid;
  grid-template-columns: auto 12rem 12rem 12rem 6rem 6rem;
  grid-template-rows: 2.5rem;
  .cell {
    ${flexCenter}
    font-size: 12px;
    text-transform: capitalize;
    padding: 0.5rem;
    &.left {
      justify-content: flex-start;
    }
  }
`
export default {
  components: {
    DashboardsStyled,
    Dashboardstyled,
    ModelManager,
    HeadersStyled,
  },
  data() {
    return {
      loading: true,
      count: 0,
      totalCount: 0,
      models: [],
      errors: [],
      searchQuery: '',
      where: null,
      perPage: 10,
      page: 0,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    hasNextPage() {
      return this.page < this.totalPages - 1
    },
    hasPreviousPage() {
      return this.page > 0
    },
    skip() {
      return this.page * this.perPage
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
  },
  methods: {
    previousPage() {
      this.page = this.page > 0 ? this.page - 1 : 0
    },
    nextPage() {
      this.page = this.page + 1
    },
    reload() {
      this.$apollo.queries.models.refetch()
    },
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            title_contains: s,
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    models: {
      query: DASHBOARDS_CONNECTION_QUERY,
      variables() {
        return {
          first: this.perPage,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'dashboardsConnection.edges', []).map(edge => edge.node)
        this.count = get(data, 'dashboardsConnection.aggregate.count', 0)
        this.totalCount = get(data, 'dashboardsConnectionTotal.aggregate.count', 0)
      },
    },
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardsStyled', [true ? _c('ModelManager', {
    attrs: {
      "title": 'Dashboards',
      "loading": _vm.loading,
      "count": _vm.count,
      "totalCount": _vm.totalCount,
      "models": _vm.models,
      "modelName": 'dashboard',
      "errors": _vm.errors,
      "hasNextPage": _vm.hasNextPage,
      "hasPreviousPage": _vm.hasPreviousPage,
      "searchQuery": _vm.searchQuery,
      "editRouteName": 'dashboard',
      "hasDeletePermission": _vm.hasWritePermission
    },
    on: {
      "update:searchQuery": function updateSearchQuery($event) {
        _vm.searchQuery = $event;
      },
      "update:search-query": function updateSearchQuery($event) {
        _vm.searchQuery = $event;
      },
      "prev-page": _vm.previousPage,
      "next-page": _vm.nextPage,
      "reload-models": _vm.reload
    },
    scopedSlots: _vm._u([{
      key: "headers",
      fn: function fn() {
        return [_c('HeadersStyled', [_c('div', {
          staticClass: "cell title left"
        }, [_vm._v(_vm._s(_vm.$tc('title', 2)))]), _c('div', {
          staticClass: "cell owner left"
        }, [_vm._v(_vm._s(_vm.$tc('username', 1)))]), _c('div', {
          staticClass: "cell"
        }, [_vm._v(_vm._s(_vm.$tc('widget', 2)))]), _c('div', {
          staticClass: "cell"
        }, [_vm._v(_vm._s(_vm.$tc('asset', 2)))])])];
      },
      proxy: true
    }], null, false, 858972021)
  }, _vm._l(_vm.models, function (model) {
    return _c('Dashboardstyled', {
      key: model.id,
      attrs: {
        "slot": model.id
      },
      slot: model.id
    }, [_c('div', {
      staticClass: "cell title left"
    }, [_vm._v(_vm._s(model.title))]), _c('div', {
      staticClass: "cell alias left"
    }, [_vm._v(_vm._s(model.owner.username))]), _c('div', {
      staticClass: "cell"
    }, [_vm._v(_vm._s(model.gridItems.length))]), _c('div', {
      staticClass: "cell"
    }, [_vm._v(_vm._s(model.assets.length))])]);
  }), 1) : _vm._e(), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }